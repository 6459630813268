import { generateImageListGallery } from "../utils/common_backend";
import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home/home_1";
import { accountCheck, getPlans } from "../utils/managedSub";
const { PrismaClient } = require('@prisma/client')
import { getSession } from "next-auth/react";
import { setSubStatus, setPlansData } from "../redux/counterSlice";
import { useDispatch } from "react-redux";
import { useRouter } from 'next/router'
import { toast } from "react-hot-toast";
import Stripe from 'stripe';
import { designsExplore, designWhereClause } from "../utils/selectFields";

// async function setDesignsPublic(designs, prisma) {
//   for (let design of designs) {
//     let _ = await prisma.designs.update({
//       where: {
//         id: design.id
//       },
//       data: {
//         visibilityId: 0,
//         visibilityName: DesignVisibility.PUBLIC
//       }
//     })
//     console.log(`updated [${_.id}] to public`);
//   }
// }


export async function getServerSideProps(ctx) {
  const { req, res } = ctx;
  const session = await getSession({ req });

  const { PrismaClient } = require("@prisma/client");
  // const { generateImageListGallery } = require("../utils/common_backend");

  const prisma = new PrismaClient();

  const designsRoot = await prisma.designs.findMany({
    where: designWhereClause(1, 'clqiep0x30005zt77hffjulvk', false),
    orderBy: {
      createdAt: 'desc'
    },
    select: designsExplore('clqiep0x30005zt77hffjulvk'),
    take: 50
  });

  
  const designs = await generateImageListGallery(designsRoot, 1, 'blurredDataUrl');

  if (!session) {
    await prisma.$disconnect();
    return {
      props: {
        subStatus: null,
        designs: JSON.stringify(designs)
      },
    };
  }

  const user = await prisma.user.findUnique({
    where: {
      email: session.user.email,
    },
  });

  const subStatus = await accountCheck(user, prisma);
  await prisma.$disconnect();

  return {
    props: {
      subStatus: JSON.stringify(subStatus),
      designs: JSON.stringify(designs)
    },
  };
}

export default function Home(props) {
  const { isPaymentSuccessfull, designs: designsRaw } = props;
  const designs = JSON.parse(designsRaw);
  const subStatusServer = props.subStatus ? JSON.parse(props.subStatus) : null;

  const dispatch = useDispatch();

  const router = useRouter()
  const { session_id } = router.query;

  const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    dispatch(setSubStatus(subStatusServer));
    // dispatch(setPlansData(JSON.parse(plans)));

    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  }, []);

  useEffect(() => {
    if (isPaymentSuccessfull) {
      // console.log('logging success', session_id);
      toast.success("Your subscription has been successfully created!\nHappy designing", { duration: 3000, icon: '🔥' });
    } else if (isPaymentSuccessfull == false) {
      toast.error(`The payment was not successful - please try again`, { duration: 3000 });
    }
  }, []);

  // console.log('designs: ', designs);

  return (
    <>
      <Home_1 designs={designs} />
    </>
  );
}
